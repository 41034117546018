define("granite/templates/account/document/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7woZL9de",
    "block": "{\"symbols\":[\"assignment\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"imagePreview\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[10,\"class\",\"ui small rounded image left floated\"],[10,\"alt\",\"Document preview\"],[11,\"src\",[23,0,[\"docSecureUrl\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"model\",\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h4\",true],[8],[1,[23,0,[\"model\",\"description\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"fileAssignments\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ui divider\"],[8],[9],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"Assignees\"],[9],[0,\"\\n\"],[4,\"each\",[[23,0,[\"fileAssignments\"]]],null,{\"statements\":[[0,\"      \"],[5,\"list-item/file-assignment\",[],[[\"@assignment\"],[[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "granite/templates/account/document/index.hbs"
    }
  });

  _exports.default = _default;
});