define("granite/components/search/result-item", ["exports", "granite/utils/uri-for-model"], function (_exports, _uriForModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isEmployee}}
    <div class="ui tiny rounded image">
      <Display::EmployeePicture @employee={{@model}} class="search-result-item__image" />
    </div>
  {{/if}}
  {{#if (or this._title this._description)}}
    <div class="content">
      {{#if this._title}}
        <div class="search-result-item__title header">
          {{this._title}}
        </div>
      {{/if}}
      {{#if this._description}}
        <div class="search-result-item__description meta">
          {{this._description}}
        </div>
      {{/if}}
    </div>
  {{/if}}
  
  */
  {
    id: "3LbAz9if",
    block: "{\"symbols\":[\"@model\"],\"statements\":[[4,\"if\",[[23,0,[\"isEmployee\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui tiny rounded image\"],[8],[0,\"\\n    \"],[5,\"display/employee-picture\",[[12,\"class\",\"search-result-item__image\"]],[[\"@employee\"],[[23,1,[]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"or\",[[23,0,[\"_title\"]],[23,0,[\"_description\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"_title\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"search-result-item__title header\"],[8],[0,\"\\n        \"],[1,[23,0,[\"_title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,0,[\"_description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"search-result-item__description meta\"],[8],[0,\"\\n        \"],[1,[23,0,[\"_description\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    meta: {
      moduleName: "granite/components/search/result-item.hbs"
    }
  });

  var modelFieldMap = {
    employee: {
      title: 'fullName',
      description: 'jobTitle',
      _id: '_id',
      picture: 'picture'
    },
    department: {
      title: 'name',
      _id: '_id'
    },
    location: {
      title: 'name',
      description: 'addressLine1',
      _id: '_id'
    }
  };

  var itemProperty = function itemProperty(property) {
    return Ember.computed('modelName', 'model', property, function () {
      var model = this.model,
          modelName = this.modelName;

      if (!modelName) {
        return null;
      }

      if (this.get(property)) {
        return this.get(property);
      }

      var propertyMap = modelFieldMap[modelName][property];
      return propertyMap && Ember.get(model, propertyMap);
    });
  };

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'a',
    href: '#',
    attributeBindings: ['href'],
    classNames: ['search__result-item', 'item'],
    classNameBindings: ['modelClass'],
    modelName: Ember.computed.reads('model.constructor.modelName'),
    isEmployee: Ember.computed.equal('modelName', 'employee'),
    _title: itemProperty('title'),
    _description: itemProperty('description'),
    __id: itemProperty('_id'),
    _picture: itemProperty('picture'),
    modelClass: Ember.computed('modelName', function () {
      return "search__result-item--".concat(this.modelName);
    }),
    click: function click(e) {
      e.preventDefault();
      var result = this.resultItem,
          router = this.router;
      router.transitionTo.apply(router, (0, _uriForModel.default)(result));
    }
  }));

  _exports.default = _default;
});